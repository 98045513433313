import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "../../components"
import { ErrorRefreshFallback } from "../../components/ErrorRefreshFallback"
import { LinkWithQuery } from "../../components/LinkWithQuery"
import ErrorBoundary from "../ErrorBoundary"
import { ClinicList } from "./ClinicList"
export const Clinics = () => {
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1 className="font-bold">My Clinics, Practices & Studios</h1>
        <LinkWithQuery to="/search-clinic">
          <Button variant="primary">
            <FontAwesomeIcon icon={faCirclePlus} /> Add
          </Button>
        </LinkWithQuery>
      </div>
      <ErrorBoundary fallback={<ErrorRefreshFallback />}>
        <ClinicList />
      </ErrorBoundary>
    </div>
  )
}
