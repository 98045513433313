import { Link, useLocation } from "react-router-dom"

interface LinkWithQueryProps {
  children: React.ReactNode
  to: string
}
export const LinkWithQuery = ({
  children,
  to,
  ...props
}: LinkWithQueryProps) => {
  const { search } = useLocation()

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  )
}
