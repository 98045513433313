import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import DefaultLogo from "../../assets/logoDefault.png"
import { ErrorRefreshFallback } from "../../components/ErrorRefreshFallback"
import { PanelButton } from "../../components/PanelButton"
import { KeycloakContext } from "../../context/KeycloakContext"

const chevronButton = (
  <button>
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
)

export const ClinicList = () => {
  let { clinics, error } = useContext(KeycloakContext)
  const navigate = useNavigate()

  const clinicListError = error?.clinicError
  if (clinicListError) {
    return <ErrorRefreshFallback />
  }

  if (clinics.length === 0) {
    return (
      <PanelButton
        className={`rounded p-6 flex justify-between items-center bg-white`}
        subtitle={"You have no linked profiles."}
      />
    )
  }

  if (clinics.length === 1) {
    const clinic = clinics[0]
    const handleClinicSelection = () => {
      navigate(`/clinics/${clinic.guid}`, { state: { data: clinic } })
    }

    return (
      <PanelButton
        className={`rounded p-6 flex justify-between items-center bg-white`}
        key={clinic.guid}
        title={clinic.name}
        onClick={handleClinicSelection}
        interactiveButton={chevronButton}
        imgSrc={clinic.logo_thumbnail_url}
        fallbackImage={DefaultLogo}
        subtitle={clinic.lookup_address}
      />
    )
  }

  const panelButtonClinics = clinics.map((clinic, idx) => {
    const isFirstItem = idx === 0
    const isLastItem = idx === clinics.length - 1
    const isRegularItem = idx !== 0 && idx !== clinics.length - 1
    const handleClinicSelection = () => {
      navigate(`/clinics/${clinic.guid}`, { state: { data: clinic } })
    }
    // Items that are neither first or last should get no rounded borders.
    let rounded
    if (isFirstItem) {
      rounded = "rounded-t"
    } else if (isLastItem) {
      rounded = "rounded-b"
    } else {
      rounded = ""
    }

    return (
      <PanelButton
        className={`${rounded} p-6 flex justify-between items-center bg-white`}
        key={clinic.guid}
        title={clinic.name}
        onClick={handleClinicSelection}
        interactiveButton={chevronButton}
        borderBottomNone={isFirstItem || isRegularItem}
        imgSrc={clinic.logo_thumbnail_url}
        fallbackImage={DefaultLogo}
        subtitle={clinic.lookup_address}
      />
    )
  })
  return <>{panelButtonClinics}</>
}
