import { Clinics } from "../Clinics"
import { LoginSecurity } from "../LoginSecurity"
export const Home = () => {
  return (
    <>
      <Clinics />
      <LoginSecurity />
    </>
  )
}
