import { useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { primary } from "../../components/variables"

const NavBarStyle = styled.div`
  width: 100%;
  padding: 0.625rem 0.6563rem;
  background: ${primary};
`
const JaneLogoWrapperStyle = styled.div`
  width: 50px;
  height: 42px;
  img {
    width: 100%;
  }
`
const JaneLogoWrapper = () => {
  return (
    <div className="col-start-2 col-end-3 flex justify-center">
      <JaneLogoWrapperStyle>
        <img
          src="https://accounts.janeapp.com/assets/jane_logo_reversed-e2dc5a5a79b815d5b1d3175d9b87dad03a49fae0627f33809f05a1fc7b47714c.png"
          alt="Jane Logo"
        />
      </JaneLogoWrapperStyle>
    </div>
  )
}

export const NavBarLogo = ({ logout }: { logout: () => void }) => {
  const [params] = useSearchParams()
  const isMobile = params.get("device") === "mobile"
  return (
    <NavBarStyle className="grid grid-cols-3">
      <JaneLogoWrapper />
      {!isMobile && (
        <button
          onClick={() => logout()}
          className="col-start-3 col-end-4 p text-white text-right pr-12 hidden lg:block"
        >
          Sign out
        </button>
      )}
    </NavBarStyle>
  )
}
